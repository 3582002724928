import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import CourseData from "../../data/course/CourseData.json";
import { Link } from "react-router-dom";

const CourseFour = () => {
  const [toggler, setToggler] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);

  return (
    <>
      <SEO title="Cursos Italiano" />
      <Layout>
        <BreadcrumbOne
          title="Cursos Italiano"
          rootUrl="/"
          parentUrl="Inicio"
          currentUrl="Cursos Italiano"
          id="cursos"
        />
        <div
          className="edu-course-area edu-section-gap-courses bg-color-white"
          id="courses-info"
        >
          <div className="container">
            <div className="row g-5 mt--10">
              <div className="col-12 col-sm-12 col-lg-12" key="1">
                <div className="form-courses-link">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/1ejNHyNJnHZxSz_CCNQCKtb_IoadsO5SRQwWbXadaSD4/edit"
                  >
                    <img
                      src="/images/cursos/Formulario_de_inscripcion.png"
                      alt="Formulario Cursos"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--10" style={{ textAlign: "center" }}>
              <h3>Inscripciones 2025 del 20/01 al 31/03</h3>
              <h4>Nuevo horario de atención de 9am a 14pm</h4>
              <h2>INFORMACIÓN RELEVANTE</h2>

              <ul className="list-style-2">
                <li><p>A1 corresponde al nivel inicial (no requiere conocimientos previos). Para inscribirse a niveles superiores (en caso de tener conocimiento del idioma), se realizará prueba de nivel a coordinar a través del mail <b><a href="mailto:adocsecretaria@gmail.com">adocsecretaria@gmail.com</a></b>.</p></li>
                <li><p>Las clases, como se indica en el cronograma, son dos veces por semana: <b>lunes</b> y <b>miércoles</b> o <b>martes</b> y <b>jueves</b>. Durante el semestre se mantiene el grupo y la modalidad, no se puede alternar.</p></li>
                <li><p>Las clases presenciales han sido hasta el 2024 en el Instituto Italiano de Cultura.</p></li>
                <li><p>Para pasar de un nivel a otro, se realiza al final del semestre prueba oral y escrita.</p></li>
                <li><p>La matrícula es de carácter anual y su pago reserva el lugar en el curso.</p></li>
                <li><p>Las mensualidades se abonan del 1 al 10 de cada mes a través de la red de cobranza Redpagos (en local físico o mediante transferencia bancaria hacia el servicio tercerizado). Luego de pasada dicha fecha, se adiciona un 10% de recargo.</p></li>
                <li><p>Se otorga 10% de descuento abonando la anualidad del curso (10 cuotas de cursos intensivos o 9 cuotas de cursos anuales).,</p></li>
                <li><p>Pedimos atención a la hora de completar el formulario de inscripción para así evitar inconvenientes (seleccione únicamente un curso). Una vez llenado el formulario, nos comunicaremos desde secretaría para indicarle cómo proceder; pedimos paciencia ya que son muchos formularios para procesar.</p></li>
                <li><p>La formación de grupos está sujeta a cuórum.</p></li>
                <li><p>La forma de adquirir el libro será indicada por los docentes una vez iniciadas las clases.</p></li>
                <li><p>El horario de secretaría es de 9am a 14pm hs de <b>lunes a viernes</b>, tanto vía telefónica como electrónica.</p></li>
              </ul>
              <h4>Inicio de clases 17/03</h4>
            </div>
            <div className="row g-5 mt--10">
              <div className="col-12 col-sm-12 col-lg-12" key="1">
                <div className="form-courses-link">
                  <img src="/images/cursos/Precios_Cursos.png" alt="Crono" id="prices-img" />
                </div>
              </div>
            </div>

            <div className="row g-5 mt--10">
              <div className="col-6 col-sm-6 col-lg-6" key="1">
                <div>
                  <img src="/images/cursos/A1.png" alt="Crono" />
                </div>
                <br></br>
                <div>
                  <img src="/images/cursos/B1.png" alt="Crono" />
                </div>
                <br></br>
                <div>
                  <img src="/images/cursos/C1.png" alt="Crono" />
                </div>
                <br></br>


                {/* <div style={{ marginTop: "30px" }}>
                  <h4 style={{ color: "red" }}>Próximas inscripciones: 01/7/23 (finalización 21/7/23)</h4>
                  <h4 style={{ color: "red" }}>Nuevo comienzo de cursos: 2/08/23 (finalización 14/12/23) </h4>
                  <h5 style={{ color: "black" }}>Para inscribirse a cualquier curso es necesario haber cursado los niveles anteriores consecutivos (A1/A2/B1/B2/C1/C2), siendo A1 el nivel inicial (no se requieren conocimientos previos). En caso de no haber cursado con Adoc y tener conocimientos del idioma, se deberá dar una prueba de nivel a coordinar con secretaría. Formación de grupos sujeta a cuórum.</h5>
                  <h5 style={{ color: "red" }}>Nuestra secretaría funciona en modo virtual. Horarios de atención: martes, jueves y viernes de 14 a 19 hs, lunes y miércoles de 16 a 19 hs</h5>
                </div> */}
              </div>

              <div className="col-6 col-sm-6 col-lg-6" key="2">
                <div>
                  <img src="/images/cursos/A2.png" alt="Crono" />
                </div>
                <br></br>
                <div>
                  <img src="/images/cursos/B2.png" alt="Crono" />
                </div>
                <br></br>
                <div>
                  <img src="/images/cursos/C2.png" alt="Crono" />
                </div>
                <div style={{ marginTop: "10%" }}>
                </div>
              </div>

              <div className="col-6 col-sm-6 col-lg-6" key="4">
              <div>
                  <img src="/images/cursos/Culturales.png" alt="Crono" />
                </div>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CourseFour;
